import revive_payload_client_4sVQNw7RlN from "/home/runner/work/injective-hub/injective-hub/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/injective-hub/injective-hub/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/injective-hub/injective-hub/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/injective-hub/injective-hub/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/injective-hub/injective-hub/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/injective-hub/injective-hub/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/injective-hub/injective-hub/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/injective-hub/injective-hub/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/injective-hub/injective-hub/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/injective-hub/injective-hub/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/home/runner/work/injective-hub/injective-hub/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/runner/work/injective-hub/injective-hub/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_X7whXyrIWR from "/home/runner/work/injective-hub/injective-hub/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/home/runner/work/injective-hub/injective-hub/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/home/runner/work/injective-hub/injective-hub/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/home/runner/work/injective-hub/injective-hub/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/home/runner/work/injective-hub/injective-hub/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import buffer_igPb1coxil from "/home/runner/work/injective-hub/injective-hub/node_modules/.c12/github_InjectiveLabs_injective_1IfPyDVKuA/nuxt-config/buffer.ts";
import tracking_liTG5GM0Yx from "/home/runner/work/injective-hub/injective-hub/node_modules/.c12/github_InjectiveLabs_injective_1IfPyDVKuA/plugins/tracking.ts";
import chunk_error_client_VGTGVjBfpU from "/home/runner/work/injective-hub/injective-hub/plugins/chunk-error.client.ts";
import gtag_RbOvmu12fv from "/home/runner/work/injective-hub/injective-hub/plugins/gtag.ts";
import handlers_oZWLY9pUCB from "/home/runner/work/injective-hub/injective-hub/plugins/handlers.ts";
import localstorage_Ek29vdFAiI from "/home/runner/work/injective-hub/injective-hub/plugins/localstorage.ts";
import store_9xNuDHGAVU from "/home/runner/work/injective-hub/injective-hub/plugins/store.ts";
import validation_30a3L3OexF from "/home/runner/work/injective-hub/injective-hub/plugins/validation.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  buffer_igPb1coxil,
  tracking_liTG5GM0Yx,
  chunk_error_client_VGTGVjBfpU,
  gtag_RbOvmu12fv,
  handlers_oZWLY9pUCB,
  localstorage_Ek29vdFAiI,
  store_9xNuDHGAVU,
  validation_30a3L3OexF
]